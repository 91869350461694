<template>
  <v-container>

    <v-row>
      <v-col>
        <h2 class="mt-3 mb-3">Noticias</h2>
        <p class="mb-0">Lo último relacionado con nuestras actividades, participantes, eventos y nuestra Universidad</p>
      </v-col>
    </v-row>

    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card class="titleUnderline" min-height="12px" elevation="1" />
      </v-col>
    </v-row>

    <v-row v-if="!isLoading">
      <v-col v-for="(article, idx) in news" :key="idx" cols="12" md="6" lg="4">
        <NewsCard size="sm" :title="article.title" :imgURL="article.imgURL" :date="article.date" :slug="article.slug" />
      </v-col>

      <v-col v-if="news.length < 1" align="center">
        <v-icon large>mdi-alert</v-icon>
        <p>No hay noticias</p>
      </v-col>

    </v-row>

    <LoadingIndicator v-else />

  </v-container>
</template>

<script>
import NewsCard from "@/components/NewsCard"
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
  name: "News",
  components: {
    NewsCard,
    LoadingIndicator,
  },
  data:() => {
    return {
      //state
      isLoading: false,
      //data
      news: []
    }
  },
  methods: {
    async loadNews() {
      try {

        this.isLoading = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/article/`)

        const news = response.data.data.map(article => {
          return {
            title: article.title,
            imgURL: article.imgURL,
            date: new Date(article.date),
            slug: article.slug,
          }
        })

        this.news = news

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.loadNews()
  },
}
</script>

<style lang="scss" scoped>

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}

</style>